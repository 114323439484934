import { Container } from "../styles/common/Container.styled";
import { SectionHeading } from "../styles/common/SectionHeading.styled";
import { AboutUsSection } from "../styles/sections/AboutSection.styled";
import {GalleryImage, GalleryItems,SingleGallery} from "../styles/sections/GallerySection.styled";
import tennis1 from "../../prod_images/FACILITIES/Tennis/tennis1.png";
import tennis2 from "../../prod_images/FACILITIES/Tennis/tennis2.png";
import tennis3 from "../../prod_images/FACILITIES/Tennis Court/tennis3.png";
import taekwondo1 from "../../prod_images/FACILITIES/Taekwondo/taekwondo1.png";
import taekwondo2 from "../../prod_images/FACILITIES/Taekwondo/taekwondo2.png";
import taekwondo3 from "../../prod_images/FACILITIES/Taekwondo/taekwondo3.png";
import skating1 from "../../prod_images/FACILITIES & WHAT WE OFFER/Skating/skating3.png";
import skating2 from "../../prod_images/FACILITIES & WHAT WE OFFER/Skating/skating1.png";
import cookery1 from "../../prod_images/FACILITIES/cookery/cookery1.png";
import cookery2 from "../../prod_images/FACILITIES/cookery/cookery2.jpg";
import cookery4 from "../../prod_images/FACILITIES/cookery/cookery4.png";
import Chess from "../../prod_images/FACILITIES/Chess/chess.png";
import Chess2 from "../../prod_images/FACILITIES/Chess/chess2.png";
import swimming1 from "../../prod_images/FACILITIES/Swimming Pool/swimmingPool.png";
import swimming2 from "../../prod_images/FACILITIES/Swimming Pool/swimming2 .png";
import swimming3 from "../../prod_images/FACILITIES/Swimming Pool/swimming3.png";
import Piano from "../../prod_images/FACILITIES/Piano/Piano.png";
import Piano1 from "../../prod_images/FACILITIES/Piano/piano1.jpg";
import Piano3 from "../../prod_images/FACILITIES/Piano/Piano3.jpg";
import LearningSession from "../../prod_images/FACILITIES/Classrooms/classrooms.png";
import Lockers from "../../prod_images/FACILITIES/Classrooms/OLIVIA'S NEST - AD SELECTION EDITED 017.png";
import ELearning from "../../prod_images/FACILITIES/e-Learning/E-learning.png";
import Dance1 from "../../prod_images/FACILITIES/Dance/Dance1.jpg";
import Dance2 from "../../prod_images/FACILITIES/Dance/Dance2.jpg";
import Dance3 from "../../prod_images/FACILITIES/Dance/Dance3.png";
import model1 from "../../prod_images/FACILITIES/Art & Modelling/model1.jpg";
import model2 from "../../prod_images/FACILITIES/Art & Modelling/model2.jpg";
import model3 from "../../prod_images/FACILITIES/Art & Modelling/model3.jpg";

export const Cocurricular = ({isFloating}) =>{
    return(
        <>
        <Container>
        <AboutUsSection id="Gallery" style={{marginTop:'8rem'}}>
    <SectionHeading>
      <h1>We offer Learning activities, Co-curricular & Daycare activities.</h1>
      {/* <h2 style={{fontSize:'3rem'}}>Learning activities</h2> */}
      <h1>Learning activities</h1>
      <p>We offer small class sizes, balanced teacher-learner ratios, a fun environment and learning through play.</p>
      </SectionHeading>

    <GalleryItems>
      <SingleGallery>
        <GalleryImage>
            <img src={LearningSession} alt="learning session on-going" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
          Class session in progress...
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={Lockers} alt="spacious lockers" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>Our lockers are really flexible which can be used for group discussions.</p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={ELearning} alt="kids modelling" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        We offer E-learning for our children, keeping them abreast of technology.
        </p>
      </SingleGallery>
    </GalleryItems>
  </AboutUsSection>
        </Container>

        <Container>
        <SectionHeading>
                <h1>Co-curricular</h1>
                <p>Enhancing a child's life skills, fitness and engaging their minds with productive sports and learning activities.</p>
                <h2 style={{fontSize:'3rem'}}> 1: Taekwondo</h2>
            </SectionHeading>
        <GalleryItems>
      <SingleGallery>
        <GalleryImage>
            <img src={taekwondo1} alt="taekwondo1" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
          We keep our children pre-occupied with sports at Olivia's Nest.
          Taekwondo session in progress.
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={taekwondo2} alt="taekwondo2" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
            Discipline is part of every sport. Rules are there to govern every sport.
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={taekwondo3} alt="taekwondo3" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
         Our children in the process of practicing taekwondo together with their instructor.
        </p>
      </SingleGallery>
    </GalleryItems>
        </Container>
    
       <Container>
       <SectionHeading>
        <h2 style={{fontSize:'3rem'}}>2: Tennis</h2>
            </SectionHeading>
        <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img src={tennis1} alt="stretching" />
                    </GalleryImage>
                    <p>Building skills and confidence on and off the court: Tennis lessons for our young stars!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img src={tennis3} alt="stretching" />
                    </GalleryImage>
                    <p>Tennis session just concluded.</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img src={tennis2} alt="stretching" />
                    </GalleryImage>
                    <p>Where fun meets fundamentals: Introducing kids to tennis with a smile and a serve!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>

       <Container>
       <SectionHeading>
       <h2 style={{fontSize:'3rem'}}> 3: Creative Art</h2>
            </SectionHeading>
        <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img src={model1} alt="stretching" />
                    </GalleryImage>
                    <p>Imagination in Action: Little Artists Bringing Their Colorful Dreams to Life!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img src={model2} alt="stretching" />
                    </GalleryImage>
                    <p>Tiny Hands, Big Creations: Exploring Creativity One Craft at a Time!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img src={model3} alt="stretching" />
                    </GalleryImage>
                    <p>Artful Adventures: Where Every Brushstroke Sparks Joy and Discovery!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>

       <Container>
       <SectionHeading>
        <h2 style={{fontSize:'3rem'}}>4: Skating</h2>
       </SectionHeading>
       <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={skating1} alt="stretching" />
                    </GalleryImage>
                    <p>Gliding into fun: Our little skaters learn the ropes with expert guidance!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={skating2} alt="stretching" />
                    </GalleryImage>
                    <p>From first steps to smooth moves: Teaching kids to skate with a smile and a helping hand!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>

       <Container>
       <SectionHeading>
                <h2 style={{fontSize:'3rem'}}>5: Swimming</h2>
            </SectionHeading>
        <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={swimming1} alt="swimming pool" />
                    </GalleryImage>
                    <p>Our swimming pool</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={swimming2} alt="coach with children in swimming pool" />
                    </GalleryImage>
                    <p>Making waves and building confidence: Little swimmers dive into fun with expert instruction!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={swimming3} alt="Children safety precaution when swimming" />
                    </GalleryImage>
                    <p>From splash to stroke: Teaching kids to swim with care, patience, and lots of smiles!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>
       
        <Container>
        <SectionHeading>
        <h2 style={{fontSize:'3rem'}}>6: Chess</h2>
       </SectionHeading>
       <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={Chess} alt="stretching" />
                    </GalleryImage>
                    <p>Tiny Tacticians: Little Minds Strategizing Big Moves on the Chessboard!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={Chess2} alt="stretching" />
                    </GalleryImage>
                    <p>Checkmate in the Making: Sparking Strategy and Fun with Every Chess Game!</p>
                </SingleGallery>
        </GalleryItems>
       </Container> 
       
       <Container>
        <SectionHeading>
            <h2 style={{fontSize:'3rem'}}>7: Cookery</h2>
        </SectionHeading>
        <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={cookery1} alt="cookery 1" />
                    </GalleryImage>
                    <p>Little Chefs at Work: Mixing, Measuring, and Making Magic in the Kitchen!</p>
                </SingleGallery>

                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={cookery2} alt="cookery 2" />
                    </GalleryImage>
                    <p>Tiny Hands, Tasty Creations: Cooking Up Fun and Delicious Moments Together!</p>
                </SingleGallery>
                
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={cookery4} alt="cookery 2" />
                    </GalleryImage>
                    <p>From Playful Prep to Yummy Treats: Exploring the Joy of Cooking with Every Recipe!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>

       <Container>
       <SectionHeading>
                <h2 style={{fontSize:'3rem'}}>8: Dance classes</h2>
            </SectionHeading>
        <GalleryItems>
        <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={Dance1} alt="stretching" />
                    </GalleryImage>
                    <p>Big Moves: Dancing Through Joy and Imagination in Every Step!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={Dance2} alt="stretching" />
                    </GalleryImage>
                    <p>Twirl, Leap, and Laugh: Little Dancers Expressing Their Creativity Through Motion!</p>
                </SingleGallery>
                <SingleGallery>
                    <GalleryImage>
                        <img style={{borderRadius:'15%'}} src={Dance3} alt="stretching" />
                    </GalleryImage>
                    <p>Rhythm and Giggles: Where Every Dance Step Sparks Fun and Friendship!</p>
                </SingleGallery>
        </GalleryItems>
       </Container>
       
       <Container>
            <SectionHeading>
                <h2 style={{fontSize:'3rem'}}>9: Piano</h2>
            </SectionHeading>
            <GalleryItems>
            <SingleGallery>
        <GalleryImage>
            <img src={Piano} alt="taekwondo1" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        Big Melodies: Discovering the Magic of Music One Note at a Time!
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={Piano1} alt="taekwondo2" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        Keyboard Kiddos: Little Musicians Creating Harmony and Fun with Every Tune!
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={Piano3} alt="taekwondo3" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        Playful Keys and Happy Hearts: Exploring the Joy of Piano in Every Lesson!
        </p>
      </SingleGallery>
            </GalleryItems>
       
       </Container>

       {/* <Container>
        <SectionHeading>
                <h2 style={{fontSize:'3rem'}}> 10: Creative Art</h2>
            </SectionHeading>
        <GalleryItems>
      <SingleGallery>
        <GalleryImage>
            <img src={model1} alt="taekwondo1" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
            Creative arts in effect with children modelling.
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={model2} alt="taekwondo2" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        Paper Dolls, Big Imagination: Crafting Colorful Characters with Every Cut and Fold!
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={model3} alt="taekwondo3" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        Doll-Making Fun: Where Paper Meets Playfulness in Every Handmade Creation!
        </p>
      </SingleGallery>
    </GalleryItems>
        </Container> */}
      
        </>
    );
};