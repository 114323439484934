import { Container } from "../styles/common/Container.styled";
import { HomeContainer } from "../styles/common/HomeConatiner";
import { SectionHeading } from "../styles/common/SectionHeading.styled";
import "../styles/sections/Gallery/Gallery.css";


import { GalleryImage, GalleryItems, HeaderContainer, SingleGallery } from "../styles/sections/GallerySection.styled";
import { AboutUsSection } from "../styles/sections/AboutSection.styled";


import culture1 from "../../prod_images/FACILITIES/Trip To Bomas of Kenya/culture1.png";
import culture2 from "../../prod_images/FACILITIES/Trip To Bomas of Kenya/culture2.png";
import culture3 from "../../prod_images/FACILITIES/Trip To Bomas of Kenya/culture3.png";

export const GallerySection = () =>{
    return (

<>
<Container>
        <AboutUsSection id="Gallery" style={{marginTop:'8rem'}}>
    <SectionHeading>
      <h1>Trip to The Bomas of Kenya</h1>
      <h3>It was a successful and our children enjoyed each and every bit of it. Travels are important as they are educative.</h3>
      <h1>Bomas Of Kenya</h1>
    </SectionHeading>
    <GalleryItems>
      <SingleGallery>
        <GalleryImage>
            <img src={culture1} alt="kids modelling" />
        </GalleryImage>
        <p style={{ marginTop: '1rem'}}>
        Adventure and Learning: Little Ones Discovering the Richness of Kenyan Culture at Bomas!
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={culture2} alt="stretching" />
        </GalleryImage>
        <p style={{ marginTop: '1rem' }}>
        From Dance to Discovery: A Fun-Filled Day of Tradition and Joy at Bomas of Kenya!
        </p>
      </SingleGallery>
      <SingleGallery>
        <GalleryImage>
            <img src={culture3} alt="kids modelling" />
        </GalleryImage>
        <p style={{ marginTop: '1rem'}}>
        Exploring Culture and Tradition: Kids Immersed in the Vibrant Heritage of Bomas of Kenya!
        </p>
      </SingleGallery>
    </GalleryItems>
  </AboutUsSection>
        </Container>
<HomeContainer>
  
</HomeContainer>
<HeaderContainer>
    <SectionHeading>
        <h1>Our media</h1>
        <p>Our media consists of various activities and surrounding of the premises</p>
    </SectionHeading>
</HeaderContainer>

<div className="wrapper_gallery">
  <div className="card_gallery">
    <img src="./images/landingPage/two.png" alt="1" />
    <div className="info_gallery">
      <h2>Pool & Bicycles</h2>
      <p> Swimming  pool and biking</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
  
   <div className="card_gallery">
    <img src="./images/landingPage/three.png" alt="1" />
    <div className="info_gallery">
      <h2>Slide area</h2>
      <p> Our children chill and refresh themselves on the sliders</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>

  <div className="card_gallery">
    <img src="./images/landingPage/four.png" alt="1" />
    <div className="info_gallery">
      <h2>Swimming pool</h2>
      <p> Clean swimming pool and swimming lessons available</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>

  <div className="card_gallery">
    <img src= "./images/birthday.jpg" alt="1" />
    <div className="info_gallery">
      <h2>Birthdays</h2>
      <p>One of the children's birthday. We celebrate together.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
  
  <div className="card_gallery">
    <img src="./images/landingPage/six.png" alt="3"/>
    <div className="info_gallery">
      <h2>Classrooms</h2>
      <p>We offer small class sizes, balanced teacher-learner ratios.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
</div>




<div className="wrapper_gallery">
   <div className="card_gallery">
    <img src="./images/modelling.jpg" alt="1" />
    <div className="info_gallery">
      <h2>Creative Arts</h2>
      <p>Creativity at its best.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>

  <div className="card_gallery">
    <img src="./images/P.E.jpg" alt="1" />
    <div className="info_gallery">
      <h2>Physical Education</h2>
      <p>Engaging our children through plays and physical well-being.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
  
  <div className="card_gallery">
    <img src="./images/tennis.jpg" alt="2"/>
    <div className="info_gallery">
      <h2>Tennis</h2>
      <p>Tennis session ongoing.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>

  <div className="card_gallery">
    <img src="./images/landingPage/five.png"  alt="2"/>
    <div className="info_gallery">
      <h2>Flexible lockers</h2>
      <p>Our lockers are flexible for both individual and group discussion</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
  
  <div className="card_gallery">
    <img src="./images/landingPage/one.png"  alt="3"/>
    <div className="info_gallery">
      <h2>Sleeping Area</h2>
      <p>World class sleeping area with comfy and well spacious beds to accommodate our children.</p>
      {/* <a href="/Gallery">More</a> */}
    </div>    
  </div>
</div>
        </>
    );
};